import PageTitle from "./components/page title/PageTitle";
import Navigation from "./components/navigation/Navigation";
import ListRoleCard from "./components/choose role/ListRoleCard";
import ReferenceButton from "./components/ReferenceButton/ReferenceButton";
import ModalHaditsReference from "./components/hadits reference/ModalHaditsReference";
import { useState } from "react";
//import { useEffect } from "react";
//import { useNavigate } from "react-router-dom";

const Home = () => {
  const [isOpenModalHaditsReference, setIsOpenModalHaditsReference] =
    useState<boolean>(false);

  const handleOpenModalHaditsReference = () => {
    setIsOpenModalHaditsReference(!isOpenModalHaditsReference);
  };

  //const navigate = useNavigate();

  // first time visit? -> on-boarding (currently disabled)
  // useEffect(() => {
  //   if (!localStorage.getItem("previouslyVisited")) {
  //     navigate("/on-boarding");
  //     localStorage.setItem("previouslyVisited", "true");
  //   }
  // });

  return (
    <div className="overflow-x-hidden">
      <Navigation />
      <div className="mt-[7.5rem] mb-12">
        <PageTitle
          title={"Halo, \nSilahkan Pilih Peranmu"}
          classPosition="justify-start sm:justify-center m-6"
        />
        <div className="mt-[-2rem] flex flex-col">
          <ListRoleCard />
          <ReferenceButton
            openModalHaditsReference={handleOpenModalHaditsReference}
          />
        </div>
      </div>
      {/* modal reference */}
      {isOpenModalHaditsReference && (
        <ModalHaditsReference
          handleOpenModal={handleOpenModalHaditsReference}
        />
      )}
      {/* end of modal reference */}
    </div>
  );
};

export default Home;
