import Dexie from "dexie";

const db = new Dexie("Havl Database");

// define a table for list viewed topicId
export const exploredTopicsDB = () => {
  db.version(1).stores({
    exploredTopics: "id",
  });
  return db;
};

export const saveExploredTopicsToIndexDB = async (id: string) => {
  try {
    await db.table("exploredTopics").add({ id: id });
  } catch (error) {
    if (error instanceof Dexie.DexieError && error.name !== "ConstraintError") {
      throw error;
    }
  }
};
