import { QuotesHadits } from "../../Quotes";
import Button from "../../../../components/ui/Button";
import SaveIcon from "./SaveIcon";

interface SavedQuotesProps {
  hadits: QuotesHadits;
  showSavedQuotes: (hadits: QuotesHadits) => void;
}

const SavedQuotes = ({ hadits, showSavedQuotes }: SavedQuotesProps) => {
  const { isiHadits, sumberHadits } = hadits;

  const showQuotes = (hadits: QuotesHadits) => {
    showSavedQuotes(hadits);
  };

  return (
    <div className="saved-quotes mb-3 px-4 sm:px-6 pt-6 pb-8 rounded-3xl text-[16px] sm:text-base w-11/12 sm:w-10/12 lg:w-7/12 lg:min-w-[36rem] bg-white drop-shadow-secondary-blue">
      <p
        onClick={() => showQuotes(hadits)}
        className="cursor-pointer text-primary-black text-[16px] md:text-[17px] leading-8"
      >
        {isiHadits}
      </p>
      <div className="flex items-center justify-between mt-4">
        <Button
          onClick={() => showQuotes(hadits)}
          label={sumberHadits.perawi}
          className="bg-[#35CAE5]/[0.075] text-[#32BFD8] text-[14.5px] md:text-[15px] font-semibold rounded-3xl px-[0.85rem] py-1"
        />
        <SaveIcon hadits={hadits} />
      </div>
    </div>
  );
};

export default SavedQuotes;
