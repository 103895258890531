import { ReactComponent as SavedItemsIcon } from "../../../../assets/navigation icon/ListSavedItems.svg";

interface SavedFloatButtonProps {
  handleOpenSavedQuotes: () => void;
}

const SavedFloatButton = ({ handleOpenSavedQuotes }: SavedFloatButtonProps) => {
  return (
    <button
      onClick={handleOpenSavedQuotes}
      className={`top-8 z-10 duration-300 fixed right-[6rem] sm:right-[9rem] bg-[#1AA5F2] rounded-3xl px-[1.0248rem] py-[0.72rem] drop-shadow-primary-blue hover:bg-[#199AE1]`}
    >
      <SavedItemsIcon className="w-[1.19rem]" />
    </button>
  );
};

export default SavedFloatButton;
