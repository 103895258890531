interface HaditsReferenceProps {
  label: string;
  number: string;
}

const HaditsReference = ({ label, number }: HaditsReferenceProps) => {
  return (
    <div className="flex items-center bg-white rounded-3xl px-4 py-3">
      <h3 className="mr-3 bg-[#41C9E2] text-[16px] text-white font-bold rounded-full min-w-7 text-center p-0.5">
        {number}
      </h3>
      <p className="text-secondary-black text-[16px] break-normal">{label}</p>
    </div>
  );
};

export default HaditsReference;
