import { ReactComponent as LogoWhite } from "../../assets/LogoWhite.svg";
import { Link } from "react-router-dom";

const NavLogo = () => (
  <Link to={"/penjelajah"}>
    <div className="logo-container">
      <LogoWhite className="w-[4.78rem]" />
    </div>
  </Link>
);

export default NavLogo;
