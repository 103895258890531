import { forwardRef, useImperativeHandle, useRef } from "react";
import { toBlob, toJpeg } from "html-to-image";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { ReactComponent as QuotesIcon } from "../../../../assets/quotesIcon.svg";
import { Fragment } from "react/jsx-runtime";
import { SumberHadits } from "../../Quotes";
import BoxHadits from "./BoxHadits";

interface HaditsProps {
  sumberHadits: SumberHadits;
  isiHadits: string;
}

interface HaditsHandle {
  shareHadits: () => void;
}

const Hadits = forwardRef<HaditsHandle, HaditsProps>(
  ({ sumberHadits, isiHadits }, ref) => {
    const elementRef = useRef(null);

    const htmlToImageConvert = async () => {
      if (elementRef.current) {
        // to handle safari browser, the image get wrong styling in the first attempt, so I make it twice
        // stupid code, but it works!!
        try {
          let dataUrl: any = await toJpeg(elementRef.current);
          setTimeout(async () => {
            if (elementRef.current) dataUrl = await toBlob(elementRef.current);
            const file = {
              files: [
                new File([dataUrl], "hadits.png", {
                  type: dataUrl.type,
                }),
              ],
            };
            // share
            await navigator.share(file);
            // time 100ms might be changed, TO DO: experimental
          }, 100);
        } catch (error) {}
      }
    };

    useImperativeHandle(ref, () => ({
      // trigger it from a parent component
      shareHadits() {
        htmlToImageConvert();
      },
    }));

    return (
      <Fragment>
        <div
          ref={elementRef}
          className={`quotes-hadits bg-white fixed inset-0 flex flex-col items-center justify-center`}
        >
          <div className="text-left">
            <QuotesIcon className="w-8 mb-2" />
          </div>
          <h2 className="text-primary-black text-center text-[17.5px] sm:text-xl w-11/12 leading-10 md:leading-10 sm:w-10/12 sm:leading-10 md:w-7/12">
            {isiHadits || <Skeleton count={2} borderRadius={24} />}
          </h2>

          <BoxHadits sumberHadits={sumberHadits} />
        </div>
      </Fragment>
    );
  }
);

export default Hadits;
