import { SumberHadits } from "../../../../../variable interface/Topic";
import BoxHadits from "./BoxHadits";

interface ListHaditsProps {
  listsumberHadits: SumberHadits[];
}

const ListHadits = ({ listsumberHadits }: ListHaditsProps) => {
  return (
    <div className="flex flex-wrap mb-4 mt-1.5 md:mt-1">
      {listsumberHadits.map((sumberHadits: SumberHadits, index) => (
        <BoxHadits sumberHadits={sumberHadits} index={index} />
      ))}
    </div>
  );
};

export default ListHadits;
