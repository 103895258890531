import ListHadits from "./ListHadits";
import IsiHadits from "./IsiHadits";
import { SumberHadits } from "../../../../../variable interface/Topic";

interface CardHaditsProps {
  listsumberHadits: SumberHadits[];
  judulHadits: string;
  isiHadits: string;
}

const CardHadits = ({
  listsumberHadits,
  isiHadits,
  judulHadits,
}: CardHaditsProps) => {
  return (
    <div className="card-hadits w-fit w-12/12 md:w-10/12 lg:w-8/12 topics pl-[1.5rem] pr-[1.5rem] sm:pr-8 pt-1.5 pb-4 rounded-3xl bg-white drop-shadow-secondary-blue mt-7">
      <IsiHadits isiHadits={isiHadits} judulHadits={judulHadits} />
      <ListHadits listsumberHadits={listsumberHadits} />
    </div>
  );
};

export default CardHadits;
