import { ReactComponent as CrossIcon } from "../../../../assets/CrossIcon.svg";
import ListHaditsReference from "./ListHaditsReference";

interface ModalHaditsReferenceProps {
  handleOpenModal: () => void;
}

const ModalHaditsReference = ({
  handleOpenModal,
}: ModalHaditsReferenceProps) => {
  return (
    <div className="bg-gray-700/[0.50] shadow overflow-x-hidden overflow-y-auto fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full h-full">
      {/* modal content */}
      <div className="bg-white rounded-3xl w-screen sm:w-10/12 md:w-8/12 lg:w-7/12 xl:w-6/12 px-6 md:px-10 py-10 m-4 sm:m-2 max-h-screen">
        {/* modal title */}
        <div className="flex justify-between items-center">
          <h2 className="text-lg font-bold w-8/12">
            Referensi Hadits yang Kami Gunakan
          </h2>
          <button
            onClick={handleOpenModal}
            className="cursor-pointer bg-[#1AA5F2]/[0.12] hover:bg-[#1AA5F2]/[0.24] rounded-full px-3 py-2.5 "
          >
            <CrossIcon className="w-4" />
          </button>
        </div>
        {/* end of modal title */}

        {/* list referensi */}
        <ListHaditsReference />
        {/* end of list referensi */}
      </div>
      {/* end of modal content */}
    </div>
  );
};

export default ModalHaditsReference;
