import { Link } from "react-router-dom";
import {
  collection,
  getCountFromServer,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../../../firebase";
import ListTopics from "../../../../components/list topics/ListTopics";
import Button from "../../../../components/ui/Button";
import PageTitle from "../../../../components/ui/PageTitle";
import { useEffect, useState } from "react";
import { Topic, Hadits } from "../../../../variable interface/Topic";

interface ListRelatedTopicsProps {
  detailTopicId: string;
}

const ListRelatedTopics = ({ detailTopicId }: ListRelatedTopicsProps) => {
  const [topics, setTopics] = useState<Topic[]>([]);

  const fetchTopics = async () => {
    // get max document index
    const coll = collection(db, "topics-updated");
    const q = query(coll);
    let documentMaxIndex: any = await getCountFromServer(q);
    documentMaxIndex = documentMaxIndex.data().count;

    // list of index document that will be used to query 4 random documents based on 4 random index
    const documentArrayOfIndex = Array.from(
      { length: 4 },
      () => Math.floor(Math.random() * documentMaxIndex) + 1
    );

    // query document
    const querySnapshot = await getDocs(
      query(coll, where("nomorTopik", "in", documentArrayOfIndex))
    );

    const data: Topic[] = querySnapshot.docs.map((docSnapshot) => {
      return {
        id: docSnapshot.id,
        judul: docSnapshot.data().judul,
        hadits: docSnapshot.data().hadits as Hadits[],
        ilustrasi: docSnapshot.data().ilustrasi,
        kategori: docSnapshot.data().kategori,
      };
    });

    setTopics(data);
  };

  const filterTopics = (topics: Topic[]) => {
    let filteredTopics = topics.slice(0, 4);
    // munculin related topic yang berbeda dengan topic yang sedang dibuka saat ini
    filteredTopics = filteredTopics.filter(
      (topic) => topic.id !== detailTopicId
    );
    return filteredTopics;
  };

  useEffect(() => {
    setTopics([]);
    // TODO : delay fetch and use SKeleton
    setTimeout(() => {
      fetchTopics();
    }, 200);
  }, [detailTopicId]);

  return (
    <div className="m-3 sm:m-0 pb-16">
      <PageTitle
        classPosition="justify-start sm:justify-center mx-3"
        title="Topik lainnya"
      />
      <ListTopics topics={filterTopics(topics)} />
      <Link to="/penjelajah">
        <Button
          className="border-2 border-[#1AA5F2] text-sm bg-white flex items-center space-x-1.5 text-[#1AA5F2] rounded-3xl font-bold px-12 py-2 mx-auto mb-8 mt-16 hover:bg-slate-100"
          label="Lebih banyak topik"
        />
      </Link>
    </div>
  );
};

export default ListRelatedTopics;
