import Navigation from "./components/navigation/Navigation";
import TopicTitle from "./components/topic/TopicTitle";
import ListCardHadits from "./components/topic/Card Hadits/ListCardHadits";
import SaveButton from "./components/save button/SaveButton";
import LineSeparator from "../../components/ui/LineSeparator";
import ListRelatedTopics from "./components/related topics/ListRelatedTopics";
import IllustrationBox from "./components/illustration/IllustrationBox";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { Topic } from "../../variable interface/Topic";
import { saveExploredTopicsToIndexDB } from "../../helper/exploreProgressIndexDB";

// define indexDB table
const DetailTopic = () => {
  const { id } = useParams();
  const [topicData, setTopicData] = useState<Topic>();
  const readTimeout = useRef<NodeJS.Timeout | null>(null);

  const saveToExploredTopicIndexDB = async () => {
    try {
      await saveExploredTopicsToIndexDB(id as string);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let totalTimeNeeded = 0;

    setTopicData({
      id: "",
      judul: "",
      hadits: [],
      ilustrasi: "",
      kategori: [],
    });
    const fetchTopicsById = async () => {
      const docRef = doc(db, "topics-updated", id as string);
      const docSnap = await getDoc(docRef);
      const data = docSnap.data() as Topic;

      const listHadits = data.hadits;
      let totalWords = 0;

      listHadits.forEach((hadits) => {
        totalWords += hadits.isiHadits.split(" ").length;
      });
      // asumption read-speed = 450 wpm
      totalTimeNeeded = (60 / 450) * totalWords; // in seconds
      setTopicData({ ...data, id: id as string });

      // Clear any previous timeout before setting a new one
      if (readTimeout.current) {
        clearTimeout(readTimeout.current);
      }

      // create new timeout
      readTimeout.current = setTimeout(() => {
        saveToExploredTopicIndexDB();
      }, totalTimeNeeded * 1000);
    };

    fetchTopicsById();

    return () => {
      if (readTimeout.current) {
        console.log("aaa");
        clearTimeout(readTimeout.current);
      }
    };
  }, [id]);

  return (
    <div>
      <Navigation />
      <div className="content m-[0.6rem] sm:m-8 mt-[6.5rem] sm:mt-28 mb-12 pb-8 sm:pb-16 px-1 sm:px-6">
        <IllustrationBox ilustrasi={topicData?.ilustrasi} />
        <TopicTitle topic={topicData} />
        {topicData && <ListCardHadits listHadits={topicData.hadits} />}
      </div>
      {topicData && <SaveButton topic={topicData} />}
      {/* <LineSeparator marginYClass="my-12" /> */}
      <div className="mb-20 bg-[#008DDA]/[0.05] py-8 rounded-[3rem] border-solid">
        {topicData ? (
          <ListRelatedTopics detailTopicId={topicData.id} />
        ) : (
          <Skeleton
            borderRadius={24}
            height={400}
            count={4}
            width={"23rem"}
            className="m-4"
            containerClassName="flex flex-wrap justify-center"
          />
        )}
      </div>
    </div>
  );
};

export default DetailTopic;
