import { useEffect, useState } from "react";
import { ReactComponent as CorrectIcon } from "../../../../assets/CorrectIcon.svg";
import Skeleton from "react-loading-skeleton";
import { exploredTopicsDB } from "../../../../helper/exploreProgressIndexDB";

interface ExplorationProgressProps {
  totalTopics: number;
}

const db = exploredTopicsDB();

const ExplorationProgress = ({ totalTopics }: ExplorationProgressProps) => {
  const [totalExploredTopics, setTotalExploredTopics] = useState<number>(0);

  useEffect(() => {
    const loadExploredTopics = async () => {
      try {
        const totalExploredTopics = (await db.table("exploredTopics").toArray())
          .length;
        setTotalExploredTopics(totalExploredTopics);
      } catch (error) {
        console.log(error);
      }
    };
    loadExploredTopics();
  });

  return (
    <div className="flex items-center rounded-full bg-white drop-shadow-secondary-blue w-full sm:w-8/12 md:w-8/12 lg:w-6/12 xl:w-5/12 mx-auto pr-6 py-0.5">
      <div className="mr-4 px-4 pl-6 py-4">
        {totalTopics === 0 ? (
          <Skeleton
            className="ml-[-0.2rem]"
            borderRadius={24}
            width={30}
            height={28}
          />
        ) : (
          <h2 className="text-3xl text-secondary-black font-bold m-auto text-center ">
            {totalExploredTopics}
            <span className="text-[14px] text-secondary-black/65 font-bold">
              /{totalTopics}
            </span>
          </h2>
        )}
      </div>

      <div className="px-1 py-3 w-full">
        <h3 className="text-secondary-black font-bold text-[14px] md:text-[15px] ml-[-1.6rem] tracking-wider mb-[-4px]">
          Topik telah dieksporasi
        </h3>
        {/* progress bar */}
        {totalTopics === 0 ? (
          <Skeleton
            borderRadius={24}
            width={"100%"}
            height={10}
            className="ml-[-1.6rem]"
          />
        ) : (
          <div className="flex items-center rounded-full">
            <div className="bg-[#D9D9D9] h-2 w-full rounded-2xl ml-[-1.6rem] flex items-center">
              <div
                style={{
                  width: (totalExploredTopics / totalTopics) * 100 + "%",
                  borderRadius: "999px",
                }}
                className={`bg-[#35CAE5] h-2 rounded-full duration-1000`}
              ></div>
            </div>
            <div
              className={`${
                totalExploredTopics === totalTopics
                  ? "bg-[#35CAE5]"
                  : "bg-[#D9D9D9]"
              } rounded-full px-2 py-1 ml-[-0.23rem]`}
            >
              <CorrectIcon className="w-[0.825rem]" />
            </div>
          </div>
        )}
        {/* end of progress bar */}
      </div>
    </div>
  );
};

export default ExplorationProgress;
