import {
  collection,
  doc,
  getCountFromServer,
  getDocs,
  limit,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { db } from "../firebase";
import { Topic, Hadits } from "../variable interface/Topic";

export const shuffleArray = (array: any[]) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

// this code was used for changing topics data, to make a better data structure for perawi hadits
export const testModifyDataTopic = async () => {
  const coll = collection(db, "topics");

  let querySnapshot = await getDocs(query(coll));

  const data: any = querySnapshot.docs.map((docSnapshot) => {
    return {
      id: docSnapshot.id,
      judul: docSnapshot.data().judul,
      hadits: docSnapshot.data().hadits as Hadits[],
      ilustrasi: docSnapshot.data().ilustrasi,
      kategori: docSnapshot.data().kategori,
      nomorTopik: docSnapshot.data().nomorTopik,
    };
  });

  const copyData = data;

  const newData = copyData.map((topic: any) => {
    return {
      id: topic.id,
      judul: topic.judul,
      hadits: topic.hadits.map((hadits: any) => {
        return {
          isiHadits: hadits.isiHadits,
          judulHadits: hadits.judulHadits,
          sumberHadits: hadits.sumberHadits.map((sumberHadits: any) => {
            return {
              perawi: sumberHadits,
              book: "",
              chapter: "",
              number: "",
            };
          }),
        };
      }),
      ilustrasi: topic.ilustrasi,
      kategori: topic.kategori,
      nomorTopik: topic.nomorTopik,
    };
  });

  newData.forEach(async (data: any) => {
    await setDoc(doc(db, "topics-updated", data.id), {
      judul: data.judul,
      hadits: data.hadits,
      ilustrasi: data.ilustrasi,
      kategori: data.kategori,
      nomorTopik: data.nomorTopik,
    });
  });
};

// this code was used for changing quotes data, to make a better data structure for perawi hadits
export const testModifyDataQuotes = async () => {
  const coll = collection(db, "quotes");

  let querySnapshot = await getDocs(query(coll));

  const data = querySnapshot.docs.map((docSnapshot) => {
    return {
      id: docSnapshot.id,
      isiHadits: docSnapshot.data().isiHadits,
      sumberHadits: docSnapshot.data().sumberHadits,
      nomorQuotes: docSnapshot.data().nomorQuotes,
    };
  });

  data.forEach(async (data) => {
    await setDoc(doc(db, "quotes-updated", data.id), {
      isiHadits: data.isiHadits,
      sumberHadits: {
        perawi: data.sumberHadits,
        book: "",
        chapter: "",
        number: "",
      },
      nomorQuotes: data.nomorQuotes,
    });
  });

  console.log(data);
};
