import { SumberHadits } from "../../../variable interface/Topic";

interface ListHaditsProps {
  listHadits: SumberHadits[];
}

const ListHadits = ({ listHadits }: ListHaditsProps) => {
  return (
    <div className="flex flex-wrap mb-4 mt-2">
      {listHadits.slice(0, 2).map((sumberHadits: SumberHadits, index) => (
        <div
          key={index}
          className={`${
            index % 2 === 0
              ? "bg-[#35CAE5]/[0.075] text-[#32BFD8]"
              : "bg-[#38DCD8]/[0.075] text-[#2fc7c3]"
          } text-[14.5px] font-semibold rounded-3xl px-[0.85rem] py-[0.3rem] mr-1.5 mt-1.5`}
        >
          {sumberHadits.perawi}
        </div>
      ))}
      {/* total sisa hadits yang tidak ditampilkan */}
      {listHadits.length > 2 && (
        <span className="bg-[#35CAE5]/[0.075] text-[#32BFD8] text-[14.5px] font-semibold rounded-3xl px-[0.85rem] py-[0.3rem] mt-1.5">
          +{listHadits.length - 2}
        </span>
      )}
    </div>
  );
};

export default ListHadits;
