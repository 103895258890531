import { ReactComponent as BookIcon } from "../../../../assets/bookIcon.svg";
import Button from "../../../../components/ui/Button";

interface ReferenceButtonProps {
  openModalHaditsReference: () => void;
}

const ReferenceButton = ({
  openModalHaditsReference,
}: ReferenceButtonProps) => {
  return (
    <div className="sm:mr-8 mt-4" onClick={openModalHaditsReference}>
      <Button
        className="text-[14px] flex items-center bg-white space-x-2 border-2 border-[#1AA5F2] text-[#1AA5F2] rounded-3xl font-bold px-5 py-1 mx-auto hover:bg-gray-50"
        icon={<BookIcon className="w-[22px]" />}
        label="Referensi Hadits"
      ></Button>
    </div>
  );
};

export default ReferenceButton;
